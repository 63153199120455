<template>
  <div :class="$style.wrap">
    <Back :class="$style.back" />
    <FilterInput @search="handleSearch" placeholderText="作品名称" />
    <van-list
      :class="$style.list"
      v-model="loading"
      :finished="finished"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <OptimizationCard
        :class="$style.listItem"
        v-for="item in list"
        :key="item.id"
        :info="item"
      />
    </van-list>
  </div>
</template>

<script>
import Back from "@/components/Back";
import FilterInput from "@/components/FilterInput";
import OptimizationCard from "@/components/OptimizationCard";
import { fetchRecommendList } from "@/api";
export default {
  components: {
    Back,
    FilterInput,
    OptimizationCard,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      error: false,
      pageSize: 10,
      page: 1,
      total: 0,
    };
  },
  methods: {
    getList() {
      const data = {
        page_size: this.pageSize,
        page: this.page,
        name: this.name,
      };
      fetchRecommendList(data)
        .then((res) => {
          const { list, total, page } = res;
          this.total = total;
          this.list = [...this.list, ...list];
          this.loading = false;
          this.page = page + 1;
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
    handleSearch(val) {
      this.page = 1;
      this.list = [];
      this.name = val;
      if (this.finished) {
        // 状态转化时会自动触发onload事件，所以不需要手动getList
        this.finished = false;
      } else {
        this.getList();
      }
    },
    onLoad() {
      this.getList();
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  padding-top: 60px;
  .back {
    position: fixed;
    top: 0;
    z-index: 9999;
  }
  .list {
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    :global {
      .van-list__loading,
      .van-list__finished-text,
      .van-list__error-text {
        width: 100%;
      }
    }
    .listItem {
      margin-bottom: 21px;
    }
  }
}
</style>
