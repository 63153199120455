// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index_wrap_4j9M4{padding-top:1.6rem}.index_wrap_4j9M4 .index_back_Nf-mg{position:fixed;top:0;z-index:9999}.index_wrap_4j9M4 .index_list_3kkmm{padding:0 .85333rem;display:flex;justify-content:space-between;flex-wrap:wrap}.index_wrap_4j9M4 .index_list_3kkmm .van-list__error-text,.index_wrap_4j9M4 .index_list_3kkmm .van-list__finished-text,.index_wrap_4j9M4 .index_list_3kkmm .van-list__loading{width:100%}.index_wrap_4j9M4 .index_list_3kkmm .index_listItem_2BNcp{margin-bottom:.56rem}", ""]);
// Exports
exports.locals = {
	"wrap": "index_wrap_4j9M4",
	"back": "index_back_Nf-mg",
	"list": "index_list_3kkmm",
	"listItem": "index_listItem_2BNcp"
};
module.exports = exports;
